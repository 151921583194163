var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('h1',{staticClass:"page-header"},[_vm._v(_vm._s(_vm._f("translate")('Change Password')))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card border-0"},[_c('div',{staticClass:"card-body"},[_c('form',[_c('form-group-thux',{staticClass:"col mb-2",attrs:{"validator":_vm.$v.form.old_password,"label":_vm._f("translate")('Old password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return [_c('b-form-input',_vm._g(_vm._b({attrs:{"type":"password"},model:{value:(_vm.form.old_password),callback:function ($$v) {_vm.$set(_vm.form, "old_password", $$v)},expression:"form.old_password"}},'b-form-input',attrs,false),listeners))]}}])}),_c('form-group-thux',{staticClass:"col mb-2 mt-2",attrs:{"validator":_vm.$v.form.new_password1,"label":_vm._f("translate")('New password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return [_c('b-form-input',_vm._g(_vm._b({attrs:{"type":"password"},model:{value:(_vm.form.new_password1),callback:function ($$v) {_vm.$set(_vm.form, "new_password1", $$v)},expression:"form.new_password1"}},'b-form-input',attrs,false),listeners))]}}])}),_c('form-group-thux',{staticClass:"col mb-0 mt-2",attrs:{"validator":_vm.$v.form.new_password2,"label":_vm._f("translate")('Confirm new password')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var attrs = ref.attrs;
var listeners = ref.listeners;
return [_c('b-form-input',_vm._g(_vm._b({attrs:{"type":"password"},model:{value:(_vm.form.new_password2),callback:function ($$v) {_vm.$set(_vm.form, "new_password2", $$v)},expression:"form.new_password2"}},'b-form-input',attrs,false),listeners))]}}])})],1)]),_c('div',{staticClass:"card-footer f-w-600 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-default mr-3",on:{"click":function($event){$event.preventDefault();return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm._f("translate")('Cancel'))+" ")]),_c('button',{staticClass:"btn btn-success",attrs:{"disabled":_vm.$v.form.$invalid},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_vm._v(" "+_vm._s(_vm._f("translate")('Change Password'))+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }