<template>
  <span>
    <h1 class="page-header">{{'Change Password' | translate}}</h1>
    <div class="row">
      <!-- begin col-12 -->
      <div class="col-12">
        <!-- begin panel -->
        <div class="card border-0">
          <div class="card-body">
            <form>
              <form-group-thux
                :validator="$v.form.old_password"
                :label="'Old password' | translate"
                class="col mb-2">
                <template slot-scope="{ attrs, listeners }">
                  <b-form-input
                    type="password"
                    v-bind="attrs"
                    v-on="listeners"
                    v-model="form.old_password" />
                </template>
              </form-group-thux>
              <form-group-thux
                :validator="$v.form.new_password1"
                :label="'New password' | translate"
                class="col mb-2 mt-2">
                <template slot-scope="{ attrs, listeners }">
                  <b-form-input
                    type="password"
                    v-bind="attrs"
                    v-on="listeners"
                    v-model="form.new_password1" />
                </template>
              </form-group-thux>
              <form-group-thux
                :validator="$v.form.new_password2"
                :label="'Confirm new password' | translate"
                class="col mb-0 mt-2">
                <template slot-scope="{ attrs, listeners }">
                  <b-form-input
                    type="password"
                    v-bind="attrs"
                    v-on="listeners"
                    v-model="form.new_password2" />
                </template>
              </form-group-thux>
            </form>
          <!-- end hljs-wrapper -->
          </div>
          <div class="card-footer f-w-600 d-flex justify-content-end">
            <button
              class="btn btn-default mr-3"
              @click.prevent="cancel()">
              {{ 'Cancel' | translate }}
            </button>
            <button
              class="btn btn-success"
              @click.prevent="onSubmit()"
              :disabled="$v.form.$invalid">
              {{ 'Change Password' | translate }}
            </button>
          </div>
        </div>
        <!-- end panel -->
      </div>
      <!-- end col-6 -->
    </div>
  </span>
</template>

<script>
import { required, sameAs } from 'vuelidate/lib/validators'
import PageOptions from '@/config/PageOptions.vue'
import { mapActions } from 'vuex'
import { TYPES as AUTH_TYPES } from './store'

export default {
  name: 'ChangePassword',
  data () {
    return {
      form: {}
    }
  },
  created () {
    PageOptions.pageContentFullWidth = false
  },
  validations: {
    form: {
      old_password: {
        required
      },
      new_password1: {
        required
      },
      new_password2: {
        required,
        sameAsPassword: sameAs('new_password1')
      }
    }
  },
  methods: {
    ...mapActions({
      changePassword: AUTH_TYPES.AUTH.changePassword
    }),
    cancel () {
      this.$router.replace({ name: 'dashboard' })
    },
    onSubmit () {
      if (!this.$v.$invalid) {
        this.changePassword(this.form).then(
          () => {
            setTimeout(() => {
              this.$router.replace({ name: 'dashboard' })
            }, 1000)
          }
        )
      }
    }
  }
}
</script>
